import $ from 'jQuery';
global.$ = global.jQuery = $;

window.addEventListener("load", function(){
  var $window = $(window);
  var $body = $("body");
  var $header = $("header");
  var $nav = $("#nav");
  var $navBtn = $("#nav_oc");
  var $navCloseBtn = $(".nav--close");
  var $kv = $("#kv");

  var allAppearFrag = false;

  var NavOpenClose = function(){
    if($nav.hasClass("nav--sp")){
      $nav.removeClass("nav--open");
      $navBtn.removeClass("nav--oc-open");
    }
  };

  var ScrollToTarget = function(event){
    var __$target = $(event.currentTarget);
    var __headerHeight;
    var __targetHash = __$target.attr("href");
    if(__targetHash === "" || __targetHash.indexOf("#") !== 0){
      return false;
    }
    if($window.width() > 750){
      __headerHeight = 0;
    }else{
      __headerHeight = $header.outerHeight();
    }
    var __targetOffsetTop = $(__targetHash).offset().top - __headerHeight;
    $("html, body").stop().animate({scrollTop: __targetOffsetTop}, 400);
    NavOpenClose();
    return false;
  };
  $(".btn--anchor").on("click", ScrollToTarget);

  var appearSPNavigation = function(){
    if($nav.hasClass("nav--open") || $nav.hasClass("nav--oc-open")){
      $nav.removeClass("nav--open");
      $navBtn.removeClass("nav--oc-open");
    }else{
      $nav.addClass("nav--open");
      $navBtn.addClass("nav--oc-open");
    }
  };

  $navBtn.on("click", appearSPNavigation);
  $navCloseBtn.on("click", appearSPNavigation);


  var setAnimation = function(){
    var $section = $(".animate-section");
    var sectionLen = $section.length;
    var timing;
    if($window.width() < 750){
      timing = $window.height() - 100;
    }else{
      timing = $window.height() - ($window.height() / 3);
    }
    for(var i = 0; i < sectionLen; i++){
      var $target = $($section[i]);
      var offsetTop = $target.offset().top;
      if(offsetTop >= $window.scrollTop() && $window.scrollTop() + timing >= offsetTop){
        var $wait = $target.find(".wait");
        var _time = 0;

        var _timer = setTimeout(function(){
          $wait.each(function(n){
            var _$this = $($wait[n]);
            var _dely = 200;
            var _mount = n * _dely;

            if($wait.hasClass("kv--lead")){
              _mount += 500;
            }

            $($wait[n]).delay(_mount).queue(function(){
              var __$this = $(this);
              if(__$this.hasClass("slide")){
                __$this.removeClass("wait").addClass('slide-move');
                if(__$this.hasClass("slide")){
                  var _timeout = setTimeout(function(){
                    __$this.removeClass("slide slide-move");
                    if(__$this.hasClass("top--btn")){
                      __$this.addClass("top--btn-animate");
                    }
                    clearTimeout(_timeout);
                  }, 1200);
                }
              }else{
                $(this).removeClass("wait").addClass('animation');
              }
            });

          });
          clearTimeout(_timer);
        }, _time);

        if($(".wait").length === 0){
          allAppearFrag = true;
          $window.off("scroll", AnimationScroller);
        }
      }else{
        //
      }
    }
  };

  var $movieBtn = $(".creator--movie, .making--movie");
  var $modal = $("#modal");
  var $modalContent = $("#modal_content");
  var $modalClose = $("#modal_close");
  var $modalOverlay = $("#modal_overlay");

  var ModalOpen = function(event){
    var _$this = $(event.currentTarget);

    if(_$this.hasClass("coming")){
      //
    }else{
      var _name = _$this.data("modal");
      var _url = _$this.data("url").split("?v=")[1];
      var _youtubeDOM = '<iframe width="560" height="315" src="https://www.youtube.com/embed/' + _url + '" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
      $modal.addClass(_name);
      $body.addClass("is-modal");
      $modal.css({display: "block"});
      $modalOverlay.css({display: "block"});

      $modalContent.append(_youtubeDOM);
      $modalClose.on("click", ModalClose);
      $modalOverlay.on("click", ModalClose);
    }

    return false;
  };

  var ModalClose = function(){
    $body.removeClass("is-modal");
    $modal.css({display: ""}).attr("class", "");
    $modalOverlay.css({display: ""});

    $modalContent.empty();
    $modalClose.off("click", ModalClose);
    $modalOverlay.off("click", ModalClose);
  };

  $movieBtn.on("click", ModalOpen);

  var AnimationScroller = function(){
    setAnimation();
  };
  $window.on("scroll", AnimationScroller);
  setAnimation();

  var _isAnimation = false;
  var Scroller = function(){
    if(_isAnimation) return;

    if($window.scrollTop() > 10){
      //$header.css({zIndex: 106});
    }else{
      $header.css({zIndex: ""});
    }

    if($window.scrollTop() >= $kv.height() + $header.outerHeight()){
      if($header.hasClass("header-scroll")){
        //
      }else{
        if($window.width() > 750){
          $header.stop().css({marginTop: -64}).addClass("header-scroll");
          $header.stop().animate({marginTop: 0},300);
        }

      }
    }else{
      if($header.hasClass("header-scroll") && $window.width() > 750){
        $header.stop().animate({marginTop: -64},200,function(){
          $header.removeClass("header-scroll");
          $header.stop().animate({marginTop: 0},100);
          _isAnimation = false;
        });
        _isAnimation = true;
      }else{
        //
      }
    }
  };

  $window.on("scroll", Scroller);
  Scroller();

  var $soaceMV = $("#space .mv, #news .post");

  global._isSP = false;
  var Resize = function(event){
    if($window.width() < 750){
      if(!_isSP){
        _isSP = true;
        if($nav.hasClass("nav--open")){
          //$nav.removeClass("nav--open")
        }
      }
      if($nav.hasClass("nav--pc")){
        $nav.removeClass("nav--pc");
        $nav.addClass("nav--sp");
      }
    }else{
      if(_isSP){
        _isSP = false;
      }
      if($nav.hasClass("nav--sp")){
        $nav.removeClass("nav--sp");
        $nav.addClass("nav--pc");
      }
    }
    $soaceMV.css({marginTop: $header.outerHeight()});

  };

  //console.log('set Resize');
  $window.on("resize", Resize);
  Resize();

  if($window.width() < 750){
    $nav.addClass("nav--sp");
  }else{
    $nav.addClass("nav--pc");
  }

  $body.removeClass("init");
});
